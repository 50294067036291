import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'normalize.css'
import './assets/css/index.less'

import ElementPlus from "element-plus";
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import { vLoading } from 'element-plus/es/components/loading/src/directive'
import { globalRegister } from './global'
 
import './permission'
import store from '@/store'
import persistStore from './utils/persistStore'

import '@/assets/iconfont/iconfont.js';
import { number } from 'echarts'

const app = createApp(App)
app.use(ElementPlus)
// app.directive('load', vLoading)
app.use(globalRegister)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(store).use(persistStore)
app.use(router)

router.isReady().then(() => {
  app.mount('#app')
})



import request, { bdService, noTokenService } from '@/utils/request'

// 值集
export function findValueBySetCode(data: any) {
  return request({
    url: 'xxx/findValueBySetCode',
    method: 'post',
    data: data,
  })
}

// 获取省市区接口
export function findAddressDictList(data: any) {
  return request({
    url: 'xxx/findAddressDictList',
    method: 'post',
    data,
  })
}

/**
 * 获取组织架构
 * @param {*} token
 */
export function findEhrOrgList(data: any) {
  return request({
    url: 'xxx/findEhrOrgList',
    method: 'post',
    data,
  })
}

// 逆地址解析
export function reverseGeocoding(data: any) {
  return bdService({
    url: 'reverse_geocoding/v3',
    method: 'get',
    params: data,
  })
}

// 登录
export function oauthToken(data: any) {
  // return request({
  //   url: 'uaa/oauth/token',
  //   method: 'post',
  //   data,
  //   // headers: { 'content-type': 'application/x-www-form-urlencoded' },
  // })
  return noTokenService({
    url: 'uaa/oauth/token',
    method: 'post',
    data,
  })
}

//
export function getInstanceList(data?: any) {
  return request({
    url: 'iot/platform-instance/list',
    method: 'get',
    data,
  })
}

// 获取设备列表
export function getEquipmentListInfos(data: any) {
  return request({
    url: 'iot/device-instance/_query',
    method: 'post',
    data,
  })
}

// 查询产品列表
export function getProduceList(data: any) {
  return request({
    url: 'iot/device-instance/product/_query',
    method: 'post',
    data,
  })
}

// 查询设备详情
export function getEquitmentDetaill(iotId: string, iotInstanceId: string) {
  return request({
    url: `iot/device-instance/${iotId}/device-detail/${iotInstanceId}`,
    method: 'get',
  })
}

import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import WebLayout from '../views/layout/webLayout.vue' //左导航
// import WebLayout from '../views/layout/webLayout2.vue' //上导航

/* 
meta:{
  title:string        必填，页面名称，显示在标题栏
  roles:string[]      必填，可访问页面的权限列表。暂时设为['admin']，登录页面给所有用户设置了'admin'权限 TODO
  hidden?:boolean     可选，是否隐藏导航，通常：详情页面传参进入不显示在导航列表
  icon?:string        可选，使用左侧导航时，显示的导航图标。头部导航默认隐藏图标。使用 components/SvgIcon组件 + iconfont图标库项目
  alwaysShow?:boolean 可选，是否显示子导航，当只有一个 hidden不为true 的子元素时，设置是否渲染子导航。alwaysShow:true 渲染 el-sub-menu，否则渲染 el-menu
  keepAlive?:boolean  可选，是否缓存页面
}
*/

export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'WebLogin',
    component: () => import('../views/webLogin.vue'),
    meta: { title: '登录', hidden: true },
  },

  {
    path: '/404',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    meta: { title: '404', hidden: true },
  },
  {
    path: '/',
    redirect: '/device/product',
    name: 'Root',
    meta: { hidden: true },
  },
]

export const asyncRoutes: Array<RouteRecordRaw> = [
  {
    path: '/device',
    component: WebLayout,
    redirect: '/device/product',
    name: 'Device',
    meta: { title: '产品中心', icon: 'icon-wodechanpin', roles: ['admin'] }, //ElementPlus图标： film
    children: [
      {
        path: 'dashboard',
        name: 'DeviceDashboard',
        component: () => import('../views/Device/DeviceDashboard.vue'),
        meta: { title: '设备概览', icon: 'icon-tubiao-bingtu', roles: ['admin'] }, //ElementPlus图标： PieChart
      },
      // {
      //   path: 'group',
      //   name: 'DeviceGroup',
      //   component: () => import('../views/Device/DeviceGroup.vue'),
      //   meta: { title: '设备分组', hidden: true, roles: ['admin']  },
      //   children: [],
      // },
      {
        path: 'product',
        name: 'ProductList',
        component: () => import('../views/Device/ProductList.vue'),
        meta: { title: '平台列表', icon: 'icon-chanpinliebiao-02', roles: ['admin'] }, //ElementPlus图标： Files
        children: [],
      },
      {
        path: 'productDetail',
        name: 'ProductDetail',
        component: () => import('../views/Device/ProductDetail.vue'),
        meta: { title: '平台详情', hidden: true, roles: ['admin'] },
      },
      {
        path: 'device',
        name: 'DeviceList',
        component: () => import('../views/Device/DeviceList.vue'),
        meta: { title: '在网设备', icon: 'icon-shebei', roles: ['admin'] }, //ElementPlus图标： Files
        children: [],
      },
      {
        path: 'deviceDetail',
        name: 'DeviceDetail',
        component: () => import('../views/Device/DeviceDetail.vue'),
        meta: { title: '设备详情', hidden: true, roles: ['admin'] },
      },
      {
        path: 'collDevice',
        name: 'CollectingDeviceList',
        component: () => import('../views/Device/CollectingDeviceList.vue'),
        meta: { title: '产品列表', icon: 'icon-guanlianshebei', roles: ['admin'] }, //ElementPlus图标： Files
        children: [],
      },
      {
        path: 'collDeviceDetail',
        name: 'CollectingDeviceDetail',
        component: () => import('../views/Device/CollectingDeviceDetail.vue'),
        meta: { title: '产品详情', hidden: true, roles: ['admin'] },
      },
    ],
  },
  {
    path: '/alarm',
    component: WebLayout,
    redirect: '/alarm/alarmList',
    name: 'Alarm',
    meta: { title: '告警中心', icon: 'icon-gaojing', alwaysShow: true, roles: ['admin'] }, //ElementPlus图标： AlarmClock
    children: [
      {
        path: 'alarmList',
        name: 'AlarmList',
        component: () => import('../views/Alarm/AlarmRcoderList.vue'),
        meta: { title: '告警记录', icon: 'icon-shenjigaojingshu', roles: ['admin'] },
      },
      {
        path: 'alarmLog',
        name: 'AlarmLog',
        component: () => import('../views/Alarm/AlarmLog.vue'),
        meta: { title: '告警日志', hidden: true, roles: ['admin'] },
      },
      {
        path: 'alarmRecords',
        name: 'AlarmRecords',
        component: () => import('../views/Alarm/AlarmRecords.vue'),
        meta: { title: '处理记录', hidden: true, roles: ['admin'] },
      },
    ],
  },
  {
    path: '/maintenance',
    component: WebLayout,
    redirect: '/maintenance/ota',
    name: 'Maintenance',
    meta: { title: '维护中心', icon: 'icon-yunwei', alwaysShow: true, roles: ['admin'] }, //ElementPlus图标： Setting
    children: [
      {
        path: 'ota',
        name: 'OTAList',
        component: () => import('../views/Maintenance/OTAList.vue'),
        meta: { title: 'OTA升级', icon: 'icon-cloud-upload', roles: ['admin'] }, //ElementPlus图标： Document
      },
      {
        path: 'otaDetail',
        name: 'OTADetail',
        component: () => import('../views/Maintenance/OTADetail.vue'),
        meta: { title: '升级包详情', hidden: true, roles: ['admin'] },
      },
      {
        path: 'batchDetail',
        name: 'BatchDetail',
        component: () => import('../views/Maintenance/BatchDetail.vue'),
        meta: { title: '批次详情', hidden: true, roles: ['admin'] },
      },
      {
        path: 'record',
        name: 'RecordList',
        component: () => import('../views/Maintenance/RecordList.vue'),
        meta: { title: '维修记录', icon: 'icon-a-Equipmentmaintenancerecords', roles: ['admin'] },
      },
      {
        path: 'recordDetail',
        name: 'RecordDetail',
        component: () => import('../views/Maintenance/RecordDetail.vue'),
        meta: { title: '维修详情', hidden: true, roles: ['admin'] },
      },

      // 测试三级导航
      // {
      //   path: 'record',
      //   name: 'Record',
      //   component: WebLayout,
      //   redirect: '/maintenance/record/list',
      //   meta: { title: '维修记录', icon: 'icon-a-Equipmentmaintenancerecords', roles: ['admin'] },
      //   children: [
      //     {
      //       path: 'list',
      //       name: 'RecordList',
      //       component: () => import('../views/Maintenance/RecordList.vue'),
      //       meta: { title: '记录列表', hidden: true, roles: ['admin'] },
      //     },
      //     {
      //       path: 'detail',
      //       name: 'RecordDetail',
      //       component: () => import('../views/Maintenance/RecordDetail.vue'),
      //       meta: { title: '维修详情', hidden: true, roles: ['admin'] },
      //     },
      //   ],
      // },

      {
        path: 'recordEdit',
        name: 'RecordEdit',
        component: () => import('../views/Maintenance/RecordEdit.vue'),
        meta: { title: '编辑维修记录', hidden: true, roles: ['admin'] },
      },
      {
        path: 'importDevice',
        name: 'ImportDevice',
        component: () => import('../views/Maintenance/ImportDevice.vue'),
        meta: { title: '导入设备', icon: 'icon-daorukecheng', roles: ['admin'] },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: [...constantRoutes],
})

export default router

import { App } from 'vue'
// import registerElement from './register-elements'
import registerProperties from './register-properties' 
import CommonSearchNew from '@/components/CommonSearchNew.vue' //搜索组件
import CommonTable from '@/components/CommonTable.vue' //表格组件 
import CommonPagination from '@/components/CommonPagination.vue' //翻页组件
import CommonForm from '@/components/CommonForm.vue' //表单组件
import SvgIcon from '@/components/SvgIcon.vue' //图表组件
import Card from '@/components/Card.vue' 

export function globalRegister(app: App): void {
  // app.use(registerElement)
  app.use(registerProperties) 
  app.component('CommonSearchNew', CommonSearchNew)
  app.component('CommonTable', CommonTable)
  app.component('CommonPagination', CommonPagination) 
  app.component('CommonForm', CommonForm)
  app.component('SvgIcon', SvgIcon)
  app.component('Card', Card)
}

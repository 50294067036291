// 获取表格最大高度，避免页面出现双滚动条，表格最大高度 = 页面高度 - 表格至页面顶部的距离 - 表格至页面底部的距离
// 默认页面基本结构是 TODO ：表格上内容（类似 面包屑header、搜索块）、表格、表格下分页组件、页面含有上下padding
export function useTableMaxHeight() {
  const minHeight = 300
  let bottomPaddingHeight = 20 //页面底部padding
  let paginationHeight = 32 + 20 + 20 //分页组件 + 分页组件上下margin
  let otherHeight = 3 //预留各种boder占位高度
  let windowHeight = 0
  if (window.innerHeight) {
    windowHeight = window.innerHeight
  }
  if (!windowHeight && document.documentElement?.clientHeight) {
    windowHeight = document.documentElement.clientHeight
  }
  let tableTop = 240 //表格距页面顶部的距离
  const tableDom: any = document.querySelector('.el-table')!
  let node = tableDom
  if (tableDom) {
    tableTop = tableDom.offsetTop
    while (node.offsetParent) {
      node = node.offsetParent
      tableTop += node.offsetTop
    }
  }
  const maxHeight = windowHeight - tableTop - bottomPaddingHeight - paginationHeight - otherHeight
  // console.log('windowHeight', windowHeight, 'tableTop ', tableTop, 'maxHeight ', maxHeight)
  return maxHeight < minHeight ? minHeight : maxHeight
}

import { defineStore } from 'pinia'
import { getEquipmentListInfos, getInstanceList, getProduceList, getEquitmentDetaill } from '@/api/common'
import qs from 'qs'
import { getBoardCounts } from '@/api/device1'

interface StateType {
  iotId: string
  produceId: String
  equitmentId: string
  totalCount: number
  productList: unknown
  equipmentList: unknown
  detaillInfos: unknown
  boardEquipmentNums: unknown
}

export const useEquipmentStore = defineStore('equipment', {
  state: (): StateType => ({
    productList: {},
    equipmentList: {},
    detaillInfos: {},
    boardEquipmentNums: {},
    iotId: '',
    produceId: '',
    equitmentId: '',
    totalCount: 0,
  }),
  actions: {
    async setDefaultid(type: 'produce' | 'equipment' | 'iot', params: string) {
      if (type === 'produce') this.produceId = params
      if (type === 'equipment') this.equitmentId = params
      if (type === 'iot') this.iotId = params

      setLocalStorage(type + 'Id', params)
    },
    async getIotInstanceAction() {
      const instance = await getInstanceList({})

      this.iotId = instance.data[0]
      this.setDefaultid('iot', this.iotId)
    },
    async getBoardEquipmentNums() {
      let data = await getBoardCounts(this.iotId)

      this.boardEquipmentNums = data.data.body.data
    },
    async getProductListAciton(params?: any) {
      let data = {
        iotInstanceId: this.iotId,
        currentPage: 1,
        pageSize: 10,
        ...params,
      }

      const productLista = await getProduceList(qs.stringify(data))
      this.productList = productLista.data.body.data

      this.totalCount = productLista.data.body.data.total
    },
    async getEquitmentAciton(params: any) {
      let data = {
        iotInstanceId: this.iotId,
        currentPage: 1,
        pageSize: 10,
        productKey: this.produceId,
        ...params,
      }
      const equitmentList = await getEquipmentListInfos(qs.stringify(data))
      this.equipmentList = equitmentList.data.body
      this.totalCount = equitmentList.data.body.total
    },
    async getEquitmentDetaillAciton() {
      const detaill = await getEquitmentDetaill(this.equitmentId, this.iotId)
      this.detaillInfos = detaill.data.body.data
    },

    // 持久化
    async persisit() {
      this.iotId = getLocal('iotId')
      this.produceId = getLocal('produceId')
      this.equitmentId = getLocal('equipmentId')
    },
  },
})

function setLocalStorage(name: string, data: any) {
  sessionStorage.setItem(name, JSON.stringify(data))
}

function getLocal(name: string) {
  let data = sessionStorage.getItem(name)
  if (data) {
    return JSON.parse(data)
  }
}

import request, { bdService } from '@/utils/request'
import qs from 'qs'

// 查询OTA升级包列表
export function otaFirmwareQuery(data: any) {
  return request({
    url: 'iot/ota/firmware/query',
    method: 'post',
    data: qs.stringify(data),
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
  })
}

// 生成升级包文件上传到OSS的URL及详细信息
export function otaFirmwareUrl(data: any) {
  return request({
    url: `iot/ota/${data.iotInstanceId}/firmware/url`,
    method: 'get',
    params: data,
  })
}

// 上传OTA升级包
export function otaFirmwareUpload(data: any) {
  return request({
    // url: `iot/ota/firmware/upload`,
    url: `iot/ota/upgrade-package/upload`,
    method: 'post',
    data: data,
  })
}

// 创建单文件OTA升级包
export function otaFirmwareSingleCreate(data: any) {
  return request({
    url: `iot/ota/${data.iotInstanceId}/firmware/single/create`,
    method: 'post',
    data: data,
  })
}

// 删除OTA升级包
export function otaFirmwareDelete(data: any) {
  return request({
    url: `iot/ota/${data.iotInstanceId}/firmware/${data.firmwareId}/delete`,
    method: 'get',
    params: data,
  })
}

// 查询OTA升级包详细信息  QueryOTAFirmware
export function otaFirmwareIDQuery(data: any) {
  return request({
    url: `iot/ota/${data.iotInstanceId}/firmware/${data.firmwareId}/query`,
    method: 'get',
    params: data,
  })
}

// 创建OTA升级包验证任务
export function otaFirmwareIDWith(data: any) {
  return request({
    url: `iot/ota/${data.iotInstanceId}/firmware/${data.firmwareId}/with/${data.productKey}`,
    method: 'post',
    data: data,
  })
}

// 查询指定升级批次下的设备升级作业列表
export function otaJob(data: any) {
  return request({
    url: `iot/ota/${data.iotInstanceId}/job/${data.jobId}`,
    method: 'get',
    // params: data,
  })
}

// 获取升级包下的升级任务批次列表（ota升级 - 升级包列表查看 - 批次管理） ListOTAJobByFirmware
export function updateJobListQuery(data: any) {
  return request({
    url: `iot/ota/firmware/updateJobList/query`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
  })
}

// 查询指定升级批次下的设备升级作业列表（ota升级 - 升级包列表查看 - 批次管理查看 - 设备列表） ListOTATaskByJob
export function otaJobList(data: any) {
  return request({
    url: `iot/ota/${data.iotInstanceId}/job/${data.jobId}`,
    method: 'get',
    params: data,
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
  })
}

// 取消指定批次下的设备升级作业（ota升级 - 升级包列表查看 - 批次管理 - 取消）对应阿里云：取消是批量取消，再点查看进入对单个设备的“取消升级”暂不做
export function otaJobCancel(data: any) {
  return request({
    url: `iot/ota/${data.iotInstanceId}/${data.jobId}/cancel`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
  })
}

// 查询指定升级批次的详情 QueryOTAJob
export function otaJobDetail(data: any) {
  return request({
    url: `iot/ota/${data.iotInstanceId}/${data.jobId}/query`,
    method: 'get',
    // params: data,
  })
}

// 创建静态升级批次
export function otaUpgradeStatic(data: any) {
  return request({
    url: `iot/ota/${data.iotInstanceId}/upgrade/static`,
    method: 'post',
    data,
  })
}

<template>
  <div class="card">
    <div class="card-wrap">
      <div class="card-content">
        <div class="card-content-bg1 red"></div>
        <div class="card-content-bg2 red"></div>
        <div class="card-text-block">
          <div class="card-item-avatar">
            <img src="https://demo.jetlinks.cn/images/device/instance/device-card.png" class="productImg" />
          </div>
          <div class="card-item-body">
            <div class="line">道尔智控产品</div>
            <div class="row">
              <div class="col">
                <div class="card-item-content-text">设备类型</div>
                <div>直连设备</div>
              </div>
              <div class="col">
                <div class="card-item-content-text">接入方式</div>
                <div>道尔智控停车系统</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-tools">
      <el-button>编辑</el-button>
      <el-button>导入</el-button>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="less">
.card {
  width: 420px;
  height: 190px;
  .card-wrap {
    position: relative;
    border: 1px solid #e6e6e6;
    overflow: hidden;
    height: 146px;
    .card-content {
      position: relative;
      padding: 30px 12px 30px 30px;
      overflow: hidden;
      .card-content-bg1 {
        position: absolute;
        right: -5%;
        height: 100%;
        width: 44.65%;
        top: 0;
        background: linear-gradient(188.4deg, rgba(9, 46, 231, 0.03) 30%, rgba(9, 46, 231, 0) 80%);
        transform: skew(-15deg);
        &.red {
          background: linear-gradient(188.4deg, rgba(229, 0, 18, 0.03) 30%, rgba(229, 0, 18, 0) 80%);
        }
      }
      .card-content-bg2 {
        position: absolute;
        right: -5%;
        height: 100%;
        width: calc(44.65% + 34px);
        top: 0;
        background: linear-gradient(188.4deg, rgba(9, 46, 231, 0.03) 30%, rgba(9, 46, 231, 0) 80%);
        transform: skew(-15deg);
        &.red {
          background: linear-gradient(188.4deg, rgba(229, 0, 18, 0.03) 30%, rgba(229, 0, 18, 0) 80%);
        }
      }
      .card-text-block {
        display: flex;
      }
      .card-item-avatar {
        margin-right: 16px;
        display: flex;
        align-items: center;
        .productImg {
          width: 80px;
          height: 80px;
        }
      }
      .card-item-body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 0;
        .line {
          overflow: hidden;
          vertical-align: bottom;
          word-break: break-all;
          width: calc(100% - 100px);
          margin-bottom: 18px;
          -webkit-line-clamp: 1;
          font-weight: 600;
          font-size: 16px;
        }
        .row {
          display: flex;
          flex-flow: row wrap;
          .col {
            position: relative;
            display: block;
            flex: 0 0 50%;
            max-width: 50%;
            .card-item-content-text {
              color: #000000b3;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .card-tools {
    display: flex;
    margin-top: 8px;
  }
}
</style>

import { BaseObject } from '@/types'
import { cloneDeep } from 'lodash-es'

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path: string) {
  const isExternal = /^(https?:|http?:|mailto:|tel:)/.test(path)
  return isExternal
}

// 深度优先遍历，遍历数组、遍历树结构对象
export function deepTraversal(node: BaseObject | BaseObject[]) {
  let stack = []
  if (Array.isArray(node)) {
    stack = cloneDeep(node)
  } else {
    stack.push(node)
  }
  let nodeList = []
  while (stack.length) {
    let item: any = stack.pop() // push + pop 后进先出
    nodeList.push(item)
    let children = item.children || []
    for (let i = children.length - 1; i >= 0; i--) {
      //使用逆序push()，以便栈底pop()时子元素是正序
      stack.push(children[i])
    }
  }
  return nodeList
}

import { defineStore } from 'pinia'
import { constantRoutes, asyncRoutes } from '@/router'
import { RouteRecordRaw } from 'vue-router'
import { cloneDeep } from 'lodash-es'
import { deepTraversal } from '@/utils'

interface PermissionState {
  routers: RouteRecordRaw[]
  cachedViews?: string[]
}

// 判断是否有给定路由的权限
const hasPermission = (roles: string[], route: RouteRecordRaw) => {
  if (route.meta && route.meta.roles) {
    if (roles.includes('ROOT')) {
      return true
    }
    return roles.some(role => {
      if (route.meta?.roles !== undefined) {
        return (route.meta.roles as string[]).includes(role)
      }
    })
  }
  return false
}

/* 
routes: [
    {
      "path": "/multi-level",
      "component": "Layout",
      "redirect": "/multi-level/multi-level1",
      "meta": {
        "title": "多级菜单",
        "icon": "multi_level",
        "hidden": false,
        "roles": ["ADMIN"],
        "keepAlive": true
      },
      "children": [
        {
          "path": "multi-level1",
          "component": "demo/multi-level/level1",
          "redirect": "/multi-level/multi-level2",
          "meta": {
            "title": "菜单一级",
            "icon": "",
            "hidden": false,
            "roles": ["ADMIN"],
            "keepAlive": true
          },
          "children": [
            {
              "path": "multi-level2",
              "component": "demo/multi-level/children/level2",
              "redirect": "/multi-level/multi-level2/multi-level3-1",
              "meta": {
                "title": "菜单二级",
                "icon": "",
                "hidden": false,
                "roles": ["ADMIN"],
                "keepAlive": true
              },
              "children": [
                {
                  "path": "multi-level3-1",
                  "component": "demo/multi-level/children/children/level3-1",
                  "name": "MultiLevel31",
                  "meta": {
                    "title": "菜单三级-1",
                    "icon": "",
                    "hidden": false,
                    "roles": ["ADMIN"],
                    "keepAlive": true
                  }
                },
                {
                  "path": "multi-level3-2",
                  "component": "demo/multi-level/children/children/level3-2",
                  "name": "MultiLevel32",
                  "meta": {
                    "title": "菜单三级-2",
                    "icon": "",
                    "hidden": false,
                    "roles": ["ADMIN"],
                    "keepAlive": true
                  }
                }
              ]
            }
          ]
        }
      ]
    },
  ]
*/
// 根据用户roles和所有异步路由列表生成当前用户异步路由列表
const filterAsyncRoutes = (routes: RouteRecordRaw[], roles: string[]) => {
  const asyncRoutes: RouteRecordRaw[] = []

  routes.forEach(route => {
    const tmpRoute = { ...route }
    if (hasPermission(roles, tmpRoute)) {
      if (tmpRoute.children) {
        tmpRoute.children = filterAsyncRoutes(tmpRoute.children, roles)
      }
      asyncRoutes.push(tmpRoute)
    }
  })
  return asyncRoutes
}

export const usePermissionStore = defineStore('permission', {
  state: (): PermissionState => {
    return {
      routers: [], //全部路由
      cachedViews: [], //需要keep-alive缓存的路由
    }
  },
  actions: {
    // 根据用户角色生成动态路由
    generateRoutes(rolesList: string[]) {
      return new Promise<RouteRecordRaw[]>(resolve => {
        let addRouters: RouteRecordRaw[] = []
        if (rolesList.indexOf('admin') != -1) {
          addRouters = cloneDeep(asyncRoutes)
        } else {
          addRouters = filterAsyncRoutes(cloneDeep(asyncRoutes), rolesList)
        }
        this.routers = [...cloneDeep(constantRoutes), ...addRouters]
        resolve(addRouters)

        this.generateCacheViews()
      })
    },
    // 根据 this.routers 生成 keep-alive 组件名列表，在router.ts中配置keepAlive属性控制是否缓存
    generateCacheViews() {
      if (this.routers.length) {
        let routeList = deepTraversal(this.routers)
        let keepAliveList = routeList.filter((item: RouteRecordRaw) => item?.meta?.keepAlive === true && item.name)
        if (keepAliveList.length) {
          this.cachedViews = keepAliveList.map((item: RouteRecordRaw) => item.name as string)
        }

        // console.log('generateCacheViews---', this.cachedViews)
      }
    },
    /* 
      function generateRoutes(roles: string[]) {
    return new Promise<RouteRecordRaw[]>((resolve, reject) => {
      // 接口获取所有路由
      listRoutes()
        .then(({ data: asyncRoutes }) => {
          // 根据角色获取有访问权限的路由
          const accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
          setRoutes(accessedRoutes);
          resolve(accessedRoutes);
        })
        .catch((error) => {
          reject(error);
        });
    }); 
    */

    // 登出清空permissionStore.routers
    clearPermission() {
      this.routers = []
      this.cachedViews = []
    },
    // 登出删除已挂载的异步路由
    resetRouter(router: any) {
      const resetWhiteNameList = ['WebLogin', 'Root', 'NotFound']
      router.getRoutes().forEach((route: any) => {
        const { name } = route
        if (name && !resetWhiteNameList.includes(name as string)) {
          router.hasRoute(name) && router.removeRoute(name)
        }
      })
    },
  },
})

import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { useUserStore } from '@/store/base'
import { usePermissionStore } from '@/store/permission'
import { usePageStore } from '@/store/page'
import pinia from '@/store'
import { RouteRecordRaw } from 'vue-router'

let whiteList = ['/login', '/404']

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore(pinia)
  const pageStore = usePageStore(pinia)
  const permissionStore = usePermissionStore(pinia)
  userStore.refreshStore() //刷新页面同步缓存里的pageStore到pinia
  pageStore.refreshStore() // 刷新页面同步缓存里的userInfo到pinia

  console.log('beforeEach', to, from)
  NProgress.start()
  let title: any = to.meta?.title || '国轩DCT服务平台'
  document.title = title

  // 首页'/device/product'非白名单，需要访问权限，先生成路由、挂载路由，再判断跳转逻辑
  if (userStore?.userInfo?.tokenInfo?.access_token) {
    if (!permissionStore.routers.length) {
      const accessRoutes = await permissionStore.generateRoutes(userStore.userInfo.rolesList || ['admin'])
      accessRoutes.forEach(route => {
        router.addRoute(route)
      })
      next({ ...to, replace: true })
    } else {
      // 未匹配到任何路由，跳转404
      if (to.matched.length === 0) {
        from.name ? next({ name: from.name }) : next('/404')
      } else {
        if (to.path == '/login') {
          next('/')
        } else {
          next()
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) != -1) {
      next()
    } else {
      pageStore.setUrl(to.path)
      next('/login')
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
